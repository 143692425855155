/* Ticket Color Code Styles */
.ticket-card-green{
    border-left: 6px solid green;
}

.ticket-card-purple{
    border-left: 6px solid rgb(98, 0, 128);
}

.ticket-card-red{
    border-left: 6px solid rgb(179, 3, 3);
}

.preview-card-green{
    border-top: 6px solid green;
}

.preview-card-purple{
    border-top: 6px solid rgb(98, 0, 128);
}

.preview-card-red{
    border-top: 6px solid rgb(179, 3, 3);
}

/* Loading Styles */
.loading{
    margin-left: 20px;
}

/* Ticket Styles */
.ticket-list{
    width: 60%;
    min-height: 100vh;
    /* max-width: 615px; */
    padding: 24px !important;
    padding-right: 7px !important;
}

.hide{
    display: none;
}

div.expand{
    max-height: 500px;
}

ul.active{
    background-color: rgba(22, 142, 245, 0.13);
    border-left: 4px solid rgb(0, 171, 236);
    font-weight: bold;
}

ul.active:hover{
    border-left: 4px solid rgba(255,255,255,0);
    /* height: 49px; */
}

ul.active:hover:before{
    border-left: 4px solid rgb(0, 171, 236);
}

.ticket-info-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}

.ticket-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ticket-info-wrapper section:first-of-type {
    width: 70%;
    margin: 0 10px;
}

.ticket-info-wrapper section.additional-info{
    margin: 70px 0 10px 0;
}

.ticket-form{
    
    width: 100%;
    height: 100%;
}

.ticket-form form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 80%;
    height: 100%;
    margin: 0 auto;
}

.ticket-list-header {
    display: flex;
    align-items: center;

}
.ticket-form-header {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ticket-form-header h2,
.ticket-list-header h2 {
    font-size: 3rem;
}

.ticket-form-header button,
.ticket-list-header button {
    width: 50px;
    height: 50px;
}
.ticket-form-header button .MuiSvgIcon-root,
.ticket-list-header button .MuiSvgIcon-root {
    width: 47px;
    height: 47px;
}
.ticket-form .input-group{
    padding: 15px;
    width: 100%;
}
.ticket-form .input-group div.MuiTextField-root{
    width: 100%;
}
.ticket-form .input-group div.MuiInputBase-root{
    width: 100% ;
    font-size: 16px !important;
}
#title-label,
.ticket-form .input-group .MuiInputLabel-root,
.ticket-form .input-group .MuiChip-label{
    font-size: 16px !important;
}
#efforts, #description {
    height: 80px !important;
}

#rightPane h3 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}


/* Media Queries */

@media screen and (max-width: 1100px){
    #rightPane {
        display: none;
    }
    #addTicketButton {
        right: 10px;
    }
    .ticket-list {
        width: 100%;
    }

}