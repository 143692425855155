@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*{
  box-sizing: border-box;
}

html{
  font-size: 62.5%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App .login-container {
  width: 320px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 1.6rem;
}

.App .login-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  margin-top: 40px;
  text-align: center;
}



.App .login-container .top-text {
  text-align: center;
}

.App .login-container form input {
  font-size: 1.8rem !important;
}
.App .login-container form label {
  font-size: 1.8rem;
}

.App .login-container form .input-group {
  width: 100%;
  margin-bottom: 50px;
}

.MuiFormHelperText-root {
  font-size: 1.2rem !important;
}

.App .login-container form .button-group button,
.App .ticket-form-container .button-group button {
  width: 100%;
  height: 56px;
  margin-bottom: 24px;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
}

.App .login-container form button:disabled,
.App .ticket-form-container form button:disabled {
  background: #EFEFEF;
  font-size: 18px;
  font-weight: normal;
  color: #2E2C48;
}

.App .login-container form .name-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-height: 812px) {
  .App .login-container {
    padding: 150px 0;
  }
}

@media only screen and (max-width: 600px) {
  #navDrawer {
  }
}
