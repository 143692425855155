@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
*{
  box-sizing: border-box;
}

html{
  font-size: 62.5%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App .login-container {
  width: 320px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 1.6rem;
}

.App .login-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  margin-top: 40px;
  text-align: center;
}



.App .login-container .top-text {
  text-align: center;
}

.App .login-container form input {
  font-size: 1.8rem !important;
}
.App .login-container form label {
  font-size: 1.8rem;
}

.App .login-container form .input-group {
  width: 100%;
  margin-bottom: 50px;
}

.MuiFormHelperText-root {
  font-size: 1.2rem !important;
}

.App .login-container form .button-group button,
.App .ticket-form-container .button-group button {
  width: 100%;
  height: 56px;
  margin-bottom: 24px;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
}

.App .login-container form button:disabled,
.App .ticket-form-container form button:disabled {
  background: #EFEFEF;
  font-size: 18px;
  font-weight: normal;
  color: #2E2C48;
}

.App .login-container form .name-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-height: 812px) {
  .App .login-container {
    padding: 150px 0;
  }
}

@media only screen and (max-width: 600px) {
  #navDrawer {
  }
}

div.login-wrapper{
    background: #e6e6e6;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
}

div.login-wrapper form.login-form{
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
}

div.MuiFilledInput-root{
    background-color: white;
}

section.login-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

div.login-wrapper form.login-form button{
    font-size: 1.4rem;
}

.divider{
    height: 10px;
    width: 100%;
    position: relative;
    top: 50px;
    margin: 0 auto;
    padding: 0 1%;
    text-align: center;
}

.divider:before, .divider:after{
    content: ' ';
    display: block;
    border-bottom: 1px solid gray;
    position: absolute;
    height: 10px;
    top: 30%;
    width: 50%;
}

.divider:before{
    left: -2%;
}

.divider:after{
    left: 52%;
}
.App{
    height: 100%;
}
/* Ticket Color Code Styles */
.ticket-card-green{
    border-left: 6px solid green;
}

.ticket-card-purple{
    border-left: 6px solid rgb(98, 0, 128);
}

.ticket-card-red{
    border-left: 6px solid rgb(179, 3, 3);
}

.preview-card-green{
    border-top: 6px solid green;
}

.preview-card-purple{
    border-top: 6px solid rgb(98, 0, 128);
}

.preview-card-red{
    border-top: 6px solid rgb(179, 3, 3);
}

/* Loading Styles */
.loading{
    margin-left: 20px;
}

/* Ticket Styles */
.ticket-list{
    width: 60%;
    min-height: 100vh;
    /* max-width: 615px; */
    padding: 24px !important;
    padding-right: 7px !important;
}

.hide{
    display: none;
}

div.expand{
    max-height: 500px;
}

ul.active{
    background-color: rgba(22, 142, 245, 0.13);
    border-left: 4px solid rgb(0, 171, 236);
    font-weight: bold;
}

ul.active:hover{
    border-left: 4px solid rgba(255,255,255,0);
    /* height: 49px; */
}

ul.active:hover:before{
    border-left: 4px solid rgb(0, 171, 236);
}

.ticket-info-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}

.ticket-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ticket-info-wrapper section:first-of-type {
    width: 70%;
    margin: 0 10px;
}

.ticket-info-wrapper section.additional-info{
    margin: 70px 0 10px 0;
}

.ticket-form{
    
    width: 100%;
    height: 100%;
}

.ticket-form form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 80%;
    height: 100%;
    margin: 0 auto;
}

.ticket-list-header {
    display: flex;
    align-items: center;

}
.ticket-form-header {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ticket-form-header h2,
.ticket-list-header h2 {
    font-size: 3rem;
}

.ticket-form-header button,
.ticket-list-header button {
    width: 50px;
    height: 50px;
}
.ticket-form-header button .MuiSvgIcon-root,
.ticket-list-header button .MuiSvgIcon-root {
    width: 47px;
    height: 47px;
}
.ticket-form .input-group{
    padding: 15px;
    width: 100%;
}
.ticket-form .input-group div.MuiTextField-root{
    width: 100%;
}
.ticket-form .input-group div.MuiInputBase-root{
    width: 100% ;
    font-size: 16px !important;
}
#title-label,
.ticket-form .input-group .MuiInputLabel-root,
.ticket-form .input-group .MuiChip-label{
    font-size: 16px !important;
}
#efforts, #description {
    height: 80px !important;
}

#rightPane h3 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}


/* Media Queries */

@media screen and (max-width: 1100px){
    #rightPane {
        display: none;
    }
    #addTicketButton {
        right: 10px;
    }
    .ticket-list {
        width: 100%;
    }

}
.profile-wrapper{
    margin: 10px 0 0 20px;
    width: 70%;
}

.user-data{
    margin-top: 10px;
}
